@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF-Pro";
  src: url("../public/fonts/SF-Pro.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "SF-Pro";
  src: url("../public/fonts/SF-Pro.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "SF-Pro";
  src: url("../public/fonts/SF-Pro.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "SF-Pro";
  src: url("../public/fonts/SF-Pro.ttf");
  font-weight: 700;
}

@layer utilities {
  .font-large-title {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 26px;
    line-height: 32px;
  }

  .font-title-1 {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 22px;
    line-height: 26px;
  }

  .font-title-2 {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 17px;
    line-height: 22px;
  }

  .font-title-3 {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 15px;
    line-height: 20px;
  }

  .font-headline {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 13px;
    line-height: 16px;
  }

  .font-body {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 13px;
    line-height: 16px;
  }

  .font-callout {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  .font-subheadline {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 11px;
    line-height: 14px;
  }

  .font-footnote {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 10px;
    line-height: 13px;
  }

  .font-caption-1 {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 10px;
    line-height: 13px;
  }

  .font-caption-2 {
    font-family: 'SF-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 10px;
    line-height: 13px;
  }
}

@layer components {
  .gradient-black {
    background: linear-gradient(180deg, #000000CC 0%, #00000099 100%);
  }

  .gradient-blue {
    background: linear-gradient(180deg, #0FB7FA 0%, #0072FB 100%);
  }

  .gradient-teal {
    background: linear-gradient(180deg, #59ADC4 0%, #00C7BE 100%);
  }

  .gradient-dark-grey {
    background: linear-gradient(180deg, #818181 0%, #4A4A4A 100%);
  }

  .gradient-light-grey {
    background: linear-gradient(180deg, #BFBFBF 0%, #919191 100%);
  }

  .gradient-green {
    background: linear-gradient(180deg, #35E461 0%, #34C734 100%);
  }

  .gradient-orange {
    background: linear-gradient(180deg, #FFC700 0%, #FF9500 100%);
  }

  .gradient-pink {
    background: linear-gradient(180deg, #FB4982 0%, #F5234B 100%);
  }

  .gradient-purple {
    background: linear-gradient(180deg, #9D82FF 0%, #5856D6 100%);
  }

  .gradient-red {
    background: linear-gradient(180deg, #FF6969 0%, #FF3B30 100%);
  }

  .gradient-yellow {
    background: linear-gradient(180deg, #FBE300 0%, #F5C200 100%);
  }

  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @keyframes notification-slide-up {
    0% {
      transform: translateY(0px);
    }

    100% {
      transform: translateY(-80px);
    }
  }

  @keyframes ai-input-slide-in {
    0% {
      width: 0px;
    }

    100% {
      width: 220px;
    }
  }

  @keyframes ai-input-slide-out {
    0% {
      width: 220px;
    }

    100% {
      width: 0px;
    }
  }

  @keyframes ai-input-placeholder-slide-in {
    0% {
      opacity: 0;
      transform: translateY(-80px);
    }

    10% {
      opacity: 1;
      transform: translateY(0px);
    }

    90% {
      opacity: 1;
      transform: translateY(0px);
    }


    100% {
      opacity: 0;
      transform: translateY(80px);
    }
  }

  @keyframes ai-input-placeholder-slide-out {
    0% {
      opacity: 1;
      translate: translateY(0px);
    }

    100% {
      opacity: 0;
      translate: translateY(40px);
    }
  }
}

@layer components {
  .send {
    color: white;
    align-self: flex-end;

    /* &:before {
      right: -7px;
      width: 20px;
      border-bottom-left-radius: 16px 14px;
    }

    &:after {
      right: -10px;
      width: 10px;
      background-color: white;
      border-bottom-left-radius: 10px;
    } */
  }

  .send-gray {
    color: white;
    align-self: flex-end;

    &:before {
      right: -7px;
      width: 20px;
      border-bottom-left-radius: 16px 14px;
    }

    &:after {
      right: -10px;
      width: 10px;
      background-color: rgba(223, 223, 224, 0);
      /* backdrop-filter: blur(20px); */
      border-bottom-left-radius: 10px;
    }
  }

  .receive {
    color: black;
    align-self: flex-start;


    /* &:before {
      left: -7px;
      width: 20px;
      border-bottom-right-radius: 16px 14px;

    }

    &:after {
      left: -10px;
      width: 10px;
      background-color: white;
      border-bottom-right-radius: 10px;

    } */
  }

  .receive-gray {
    color: black;
    align-self: flex-start;

    &:before {
      left: -7px;
      width: 20px;
      border-bottom-right-radius: 16px 14px;
    }

    &:after {
      left: -10px;
      width: 10px;
      background-color: rgba(223, 223, 224, 0);
      /* backdrop-filter: blur(20px); */
      border-bottom-right-radius: 10px;
    }
  }

  .p-chat {
    max-width: 255px;
    word-wrap: break-word;
    line-height: 24px;
    position: relative;
    /* padding: 8px 12px; */
    border-radius: 15px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 50%;

    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(245, 245, 247, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}